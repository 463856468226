import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Nav, OverlayTrigger, Tooltip, ButtonGroup, ToastContainer, Spinner } from 'react-bootstrap';

import { ArticleService } from '../../services/ArticleService';
import { ReglementService } from '../../services/ReglementService';
import { EditionService } from '../../services/EditionService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import ReglementDetails from '../../components/side-details/reglement/reglement-details/ReglementDetails';
import ReglementEdition from '../../components/side-details/reglement/reglement-edition/ReglementEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Reglements.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import SendMailDialog from "../../components/send-mail-dialog/SendMailDialog";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, formattedDateInput, currencyFormat, getFile } from '../../helper/Helper'

const Reglements = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let reglementsParams = useRef({
    'start': 0,
    'limit': null,
    'search': null,
    'filters': {},
    'sort': null,
    'order': null,
    'filtre': null,
    'resume': true
  });

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGetReglementsLoading, setIsGetReglementsLoading] = useState()
  const [reglements, setReglements] = useState()
  const [resumeCategories, setResumeCategories] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentReglement, setCurrentReglement] = useState()
  const [isReglementEditing, setIsReglementEditing] = useState(false)
  const [reglementEditingId, setReglementEditingId] = useState()
  const [sendMailDialog, setSendMailDialog] = useState({})

  let searchRef = useRef(null);

  const [tableRowElementActions] = useState([
    { icon: "print", title: "Imprimer le reçu", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  const [reglementElementActions] = useState([
    { icon: "print", title: "Imprimer le reçu", action: "downloadPDF" },
    { icon: "email", title: "Envoyer par mail", action: "sendEmail" }
  ])

  useEffect(() => {
    if (grants) {

      !grants.reglements.valeur && setGlobalError({ statusText: `Reglements : vous n'avez pas les droit d'accès à cette ressource.` })

      /* granted(grants, "reglements.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeReglementDialog" })
      granted(grants, "reglements.remove") && reglementElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeReglementDialog" }) */
    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('reglementId') && getReglement(searchParams.get('reglementId'))
      setPreferences()
      getReglements()
    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.reglements['tableHeaders'], parametres.reglements['fieldsLabel']))
    reglementsParams.current['sort'] = preferences.reglements['tableSort']
    reglementsParams.current['order'] = preferences.reglements['tableOrder']
    reglementsParams.current['limit'] = preferences.reglements['tableCount']
    reglementsParams.current['fields'] = Object.keys(preferences.reglements['tableHeaders']).join(',')
  }

  function getDayTitle(place) {
    let date = reglementsParams.current?.filters['filter[date_valeur]']?.find(f => f.operation == "equal_to")?.value ? new Date(reglementsParams.current?.filters['filter[date_valeur]']?.find(f => f.operation == "equal_to")?.value) : new Date()
    var newDate = new Date(date.getTime())
    newDate.setDate(date.getDate() + place);
    var days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    var dayName = days[newDate.getDay()];
    return `${dayName} ${newDate.getDate()}`
  }

  function getReglement(reglementId) {
    ReglementService.getReglement(folderDetails._id, reglementId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentReglement(res.data.data)
        setIsReglementEditing(false)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getReglements() {
    setCheckedElementIdList([])
    if (granted(grants, "reglements.consult")) {
      setIsGetReglementsLoading(true)

      if (!reglementsParams.current['filtre']) {
        ReglementService.getReglements(folderDetails._id, reglementsParams.current).then(res => {
          if (!res?.data?.data?.reglements) {
            setReglements()
            setIsGetReglementsLoading(false)
          } else {
            if (!resumeCategories) {
              setResumeCategories(Object.keys(res?.data?.data?.resume).filter(r => res?.data?.data?.resume[r]?.libelle).sort((a, b) => a > b ? 1 : -1))
            }
            setReglements({
              reglements: res.data.data.reglements,
              count: res.data.data.resume.total_count,
              total: res.data.data.resume.total_montant,
              resume: res.data.data.resume
            })
            setIsGetReglementsLoading(false)
          }
        }).catch(error => {
          setGlobalError(error)
          setIsGetReglementsLoading(false)
        });
      } else {
        ReglementService.getReglementsSummary(folderDetails._id, reglementsParams.current).then(res => {
          if (res.status == 204) {
            setReglements({
              reglements: null,
              total: 0,
              count: 0,
              resume: null
            })
            setIsGetReglementsLoading(false)
          } else if (!res?.data?.data[reglementsParams?.current['filtre']]) {
            setReglements({
              reglements: null,
              total: 0,
              count: 0,
              resume: res?.data?.data?.resume
            })
            setIsGetReglementsLoading(false)
          } else {
            setReglements({
              reglements: res.data.data[reglementsParams.current['filtre']].length > 0 ? res.data.data[reglementsParams.current['filtre']] : null,
              total: res.data.data.resume[reglementsParams.current['filtre']].total,
              count: res.data.data.resume[reglementsParams.current['filtre']].count,
              resume: res.data.data.resume
            })
            setIsGetReglementsLoading(false)
          }

        }).catch(error => {
          setGlobalError(error)
          setIsGetReglementsLoading(false)
        });
      }
    }
  }

  function downloadPDF(reglement) {
    EditionService.getReglement(folderDetails._id, reglement._id, "pdf").then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        getFile(res.data, `Reçu`, "pdf")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function sendEmail(reglement) {
    setSendMailDialog({
      "clientId": reglement.client_id,
      "folderId": folderDetails._id,
      "documentType": "reglements",
      "documentId": reglement._id,
      "show": true
    })
  }

  function downloadCheckedReglements() {
    EditionService.getReglements(folderDetails._id, { reglements: checkedElementIdList }).then(res => {
      if (!res.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        if (checkedElementIdList.length > 1) {
          getFile(res.data, `Règlements`, "zip")
        } else {
          getFile(res.data, `Règlement`, "pdf")
        }
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getReglementsPDF(idList) {
    if (granted(grants, "reglements.consult")) {
      !idList && setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': reglementsParams.current.search,
        'sort': reglementsParams.current.sort,
        'order': reglementsParams.current.order,
        'filters': reglementsParams.current.filters
      }

      if(idList){
        params['reglements'] = idList
      }

      EditionService.getListe(folderDetails._id, params, "reglements", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Règlements - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }



  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['reglements']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentReglement(reglement) {
    reglement?._id ? setSearchParams({ reglementId: reglement._id }) : setSearchParams(searchParams.delete('reglementId'))
    setCurrentReglement(reglement)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    reglementsParams.current['start'] = (reglementsParams.current.limit * p) - reglementsParams.current.limit
    getReglements()
  }

  function changeLimit(l) {
    reglementsParams.current['limit'] = l
    updatePreference({ ["tableCount"]: l })
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      reglementsParams.current['start'] = 0
      reglementsParams.current['search'] = value ? value : ""
      searchRef.current.value = value ? value : ""
      getReglements()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      reglementsParams.current['start'] = 0
      reglementsParams.current['filters'] = f ? f : {}
      getReglements()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    reglementsParams.current['filters'] = {}
    getReglements()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    reglementsParams.current['sort'] = s
    reglementsParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getReglements()
  }

  function onReglementSaved(articleId) {
    getReglement(articleId)
    getReglements()
  }

  function closeSide() {
    setIsReglementEditing(false)
    setCurrentReglement()
  }

  function cancelEdition() {
    setIsReglementEditing(false)
    !currentReglement && setSideWidth()
  }

  function createNewReglement() {
    setIsReglementEditing(true)
    setReglementEditingId()
  }

  function addFilterDate(value) {

    let showDate = reglementsParams.current?.filters['filter[date_valeur]']?.find(f => f.operation == "equal_to")?.value ? new Date(reglementsParams.current?.filters['filter[date_valeur]']?.find(f => f.operation == "equal_to")?.value + 'T12:12:00') : new Date()

    if (value == "today") {
      showDate = new Date()
    }

    if (value == "next") {
      showDate.setDate(showDate.getDate() + 1)
    }

    if (value == "prev") {
      showDate.setDate(showDate.getDate() - 1)
    }

    setIsTableFilterVisible(true)
    if (reglementsParams.current.filters) {
      reglementsParams.current.filters['filter[date_valeur]'] = [{ "operation": "equal_to", "operator": "=", "value": formattedDateInput(showDate) }]
    } else {
      reglementsParams.current.filters = { 'filter[date_valeur]': [{ "operation": "equal_to", "operator": "=", "value": formattedDateInput(showDate) }] }
    }

    let tableHeadersCopy = [...tableHeaders];
    tableHeadersCopy.find(t => t.dataTarget == "date_valeur").hidden = false
    setTableHeaders(tableHeadersCopy);

    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    getReglements()

  }

  function addFilterInProgress(e) {

    if (e.target.checked) {
      setIsTableFilterVisible(true)
      if (reglementsParams.current.filters) {
        reglementsParams.current.filters['filter[comptabilisee]'] = [{ "operation": "equal_to", "operator": "=", "value": 'false' }]
      } else {
        reglementsParams.current.filters = { 'filter[comptabilisee]': [{ "operation": "equal_to", "operator": "=", "value": 'false' }] }
      }
    } else {
      delete reglementsParams.current.filters['filter[comptabilisee]']
    }

    let tableHeadersCopy = [...tableHeaders];
    tableHeadersCopy.find(t => t.dataTarget == "comptabilisee").hidden = false
    setTableHeaders(tableHeadersCopy);

    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    getReglements()

  }

  function changeParamFiltre(value) {
    reglementsParams.current['filtre'] = value
    setCurrentPagination(1)
    reglementsParams.current['start'] = 0
    getReglements()
  }

  return (
    <Container fluid id="Reglements" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Règlements</h4>
        {granted(grants, "reglements.edit") &&
          <Button variant="primary" onClick={() => createNewReglement()}>Créer un règlement</Button>
        }
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <SendMailDialog clientId={sendMailDialog.clientId} folderId={sendMailDialog.folderId} documentType={sendMailDialog.documentType} documentId={sendMailDialog.documentId} show={sendMailDialog.show} setCloseModal={() => setSendMailDialog({ show: false })} />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row className="mb-4">
            {granted(grants, "reglements.consult") &&
              <>
                <Col xs="12" xl="3" className="mb-sm-2 mb-xl-0">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="text"
                      onChange={e => changeSearchValue(e.target.value)}
                      ref={searchRef}
                    />
                    {reglementsParams.current['search'] &&
                      <Button onClick={() => changeSearchValue()} variant="gray-100" className="border border-start-0">
                        <i className="material-icons i-lg">clear</i>
                      </Button>
                    }
                  </InputGroup>
                </Col>

                <Col xs="12" xl="auto" className="d-flex align-items-center mb-sm-2 mb-xl-0">
                  {Object.keys(reglementsParams.current.filters).length == 0 &&
                    <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                      <Button className="me-2" variant="secondary" onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                        <i className="material-icons i-lg">filter_alt</i>
                      </Button>
                    </OverlayTrigger>
                  }
                  {Object.keys(reglementsParams.current.filters).length != 0 &&
                    <Button className="me-2" variant="warning" onClick={() => clearFilters()}>
                      Suppr. les filtres
                      <i className="material-icons i-lg ms-2 d-inline-flex">clear</i>
                    </Button>
                  }
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="me-2" onClick={() => getReglementsPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({reglements?.count})
                  </Button>

                  {checkedElementIdList?.length > 0 &&
                    <Dropdown className="d-inline-block me-2">
                      <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                        {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => getReglementsPDF(checkedElementIdList)}>Imprimer la liste sélectionnée</Dropdown.Item>
                        <Dropdown.Item onClick={() => downloadCheckedReglements()}>Imprimer les règlements</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  }

                  <ButtonGroup className="me-3">
                    <Button onClick={() => addFilterDate("today")} variant={reglementsParams.current?.filters && reglementsParams.current?.filters['filter[date_valeur]']?.find(f => f.operation == "equal_to")?.value == formattedDateInput(new Date()) ? "primary-300" : "secondary"} >Règlements du jour</Button>
                    <Button variant="secondary" onClick={() => addFilterDate("prev")}>
                      {getDayTitle(-1)}
                      <i className="material-icons float-start me-1">
                        arrow_back_ios_new
                      </i>
                    </Button>
                    <Button variant="secondary" onClick={() => addFilterDate("next")}>
                      {getDayTitle(1)}
                      <i className="material-icons float-end ms-1">
                        arrow_forward_ios
                      </i>
                    </Button>
                  </ButtonGroup>

                  <Form.Check
                    className="me-é"
                    label="Paiements en cours uniquement"
                    type="switch"
                    onChange={e => addFilterInProgress(e)}
                    checked={reglementsParams.current?.filters && reglementsParams.current?.filters['filter[comptabilisee]']?.find(f => f.operation == "equal_to")?.value == 'false'}
                  />

                </Col>
              </>
            }
          </Row>

          {granted(grants, "reglements.consult.totaux") &&
            <Row className="mb-3">
              <Col>
                <Nav variant="pills" activeKey={reglementsParams.current.filtre || "all"} >
                  <Nav.Item>
                    <Nav.Link onClick={() => changeParamFiltre()} eventKey="all" className="border border-2 border-primary d-flex flex-column me-2 h-100">
                      <span className="fw-bold">Tous ({reglements?.resume?.total_count || 0})</span>
                      <span className="">
                        Total : {currencyFormat(reglements?.resume?.total_montant || 0)}
                      </span>

                    </Nav.Link>
                  </Nav.Item>
                  {resumeCategories && resumeCategories.map(category => (
                    <Nav.Item key={category}>
                      <Nav.Link onClick={() => changeParamFiltre(category)} eventKey={category} className="border d-flex flex-column me-2 h-100">
                        <span className="fw-bold">{category} ({(reglements?.resume && reglements?.resume[category]?.count) ? reglements?.resume[category]?.count : 0})</span>
                        <span className="">
                          Total : {currencyFormat((reglements?.resume && reglements?.resume[category]?.montant) ? reglements?.resume[category]?.montant : 0)}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
            </Row>
          }

          {granted(grants, "reglements.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetReglementsLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={reglements?.reglements}
                    currentElement={currentReglement}
                    changeCurrentElement={e => changeCurrentReglement(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={reglementsParams.current}
                  />
                  <Pagination className="mt-3" itemsLength={reglements?.count} elementsPerPage={reglementsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {(currentReglement || isReglementEditing) &&
            <SideDetails
              editMode={isReglementEditing}
              currentElement={isReglementEditing ? null : { id: currentReglement?._id, label: new Date(currentReglement?.date).toLocaleString(), title: currentReglement?.client_nom }}
              changeCurrentElement={e => changeCurrentReglement(e)}
              setSideClose={closeSide}
              setSideCancel={cancelEdition}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                isReglementEditing ?
                  <ReglementEdition folderId={folderDetails._id} reglementEditingId={reglementEditingId} onSetSaved={f => onReglementSaved(f)} />
                  :
                  <ReglementDetails folderId={folderDetails._id} reglementId={currentReglement._id} elementActions={reglementElementActions} setElementAction={(a, e) => eval(a)(e)} />
              }
            />
          }

        </Col>
      </Row>

    </Container>

  );
}

export default Reglements;