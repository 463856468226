import React, { useState, useEffect } from 'react';

import { UserService } from '../../services/UserService';
import { FolderService } from '../../services/FolderService';
import { FactureService } from '../../services/FactureService';
import { ProformaService } from '../../services/ProformaService';
import { AbonnementService } from '../../services/AbonnementService';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import { GlobalContext, GlobalDispatchContext } from "../../services/GlobalProvider";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Button, Row, Col, Form, Table, InputGroup, FormControl, OverlayTrigger, Tooltip, DropdownButton } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import Brouillons from "./brouillons/Brouillons";
import ErrorManager from "../../components/error-manager/ErrorManager";

import '../../config.inc';
import './Accueil.scss';


export default function Accueil() {

  const { userDetails, folderDetails, grants, folders, setFolderDetails, preferences, globalContextLoaded } = React.useContext(GlobalContext);
  const [globalError, setGlobalError] = useState()

  /*   evtSource.onmessage = (event) => {
  
      console.log('-----event')
      console.log(event)
  
      setMessage(event.data)
    } */

  /* 
  ---> APPEL OK : 
  evtSource.addEventListener("time", (event) => {
      console.log(event);
      setMessage(event.data)
    }); */

  //content-type=text/event-stream;charset=UTF-8


  /*   const eventSource = new EventSource(resoureUrl, {
      headers: {
          'Authorization': 'Bearer ' + authorizationToken
      }
    }); */


  /*   const axiosAPI = axios.create({
      baseURL: global.config._API_URL_,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }) */


  /*   useEffect(() => {
  
      axios.get(`https://api.xlprog.nc/admin/testsse/`)
      .then(res => {
        console.log(res)
      })
    
      
    }, []); */


  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */

  /*   const token = localStorage.getItem('access_token');
  
    const evtSource = new EventSource(`${global.config._API_URL_}/admin/sse?key=${token}`, {
      //withCredentials: true,
      //authorizationHeader: "Bearer " + token,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/event-stream',
        'charset': 'charset=UTF-8'
      }
    } ); */

  /* const [message, setMessage] = useState("XX") */

  const navigate = useNavigate();

  function openBrouillon(brouillonId, documentType) {
    navigate(`/${folderDetails._id}/${documentType}/brouillon?brouillonId=${brouillonId}`)
  }

  function createNewProforma() {
    setGlobalError()
    ProformaService.postProforma(folderDetails._id, { proformas: [{ "type": "proforma" }] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "proformas")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function createNewFacture() {
    setGlobalError()
    FactureService.postFacture(folderDetails._id, { factures: [{ "type": "facture" }] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "factures")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function createNewAbonnement() {
    setGlobalError()
    AbonnementService.postAbonnement(folderDetails._id, { abonnements: [{ "type": "abonnement" }] }).then(res => {
      for (var brouillonId in res.data.data.brouillons) {
        openBrouillon(brouillonId, "abonnements")
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  return (

    <Container fluid id="Accueil" className="py-4">
      <Row>
        <Col>
          <ErrorManager error={globalError} />
        </Col>
      </Row>
      <Row className="row mt-5">
        <Col sm={5} xxl={{ span: 3, offset: 1 }} className="text-end">
          {userDetails &&
            <h6 className="mb-2 text-primary">Bonjour {userDetails?.identifiant}</h6>
          }
          <h3 className="fw-bolder">Par où souhaitez<br></br>vous commencer ?</h3>
        </Col>

        <Col sm={7} xxl={9} className="col-right">
          <div className="ms-5 mb-4">
            <h6>Créer un nouveau document</h6>
            <div className="d-flex">
              <Button variant="secondary" className="me-2" onClick={() => createNewProforma()}>Proforma</Button>
              <Button variant="secondary" className="me-2" onClick={() => createNewFacture()}>Facture</Button>
              <Button variant="secondary" className="me-2" onClick={() => createNewAbonnement()}>Abonnement</Button>
            </div>
          </div>
          <div className="ms-5 mb-4">
            <Brouillons title={"Reprendre un brouillon de proforma"} brouillonTypes={["proforma"]} openBrouillon={b => openBrouillon(b, "proformas")} />
          </div>
          <div className="ms-5 mb-4">
            <Brouillons title={"Reprendre un brouillon de facture"} brouillonTypes={["facture", "avoir"]} openBrouillon={b => openBrouillon(b, "factures")} />
          </div>
          <div className="ms-5 mb-4">
            <Brouillons title={"Reprendre un brouillon d'abonnement"} brouillonTypes={["abonnement"]} openBrouillon={b => openBrouillon(b, "abonnements")} />
          </div>
        </Col>
      </Row>
    </Container>

  );
}


{/*
    <Container fluid id="Accueil" className="py-4 h-100">
      <Row>
        <Col class="p-5">


          <p>En développement</p>

        </Col>

         <Col xs={4} className="border rounded p-3">

          <h6 className="mb-4">Facture rapide</h6>

          <div className="d-flex mb-3">
            <div className="flex-fill pe-2">
              <InputGroup>
                <i className="material-icons input-icon text-gray-400">search</i>
                <FormControl
                  className="rounded-start input-icon-space"
                  placeholder="Client"
                  type="search"
                />
              </InputGroup>
            </div>
            <div>
              <OverlayTrigger
                placement="left"
                delay={{ show: 800 }}
                overlay={
                  <Tooltip>
                    Créer un client
                  </Tooltip>
                }
              >
                <Button variant="secondary" >
                  <i className="material-icons i-lg">person_add</i>
                </Button>
              </OverlayTrigger>
            </div>
          </div>

          <div className="p-3 bg-gray-100 rounded mb-3">


            <Table responsive size="sm" borderless className="text-nowrap align-middle mb-2">
              <thead className="text-uppercase text-gray-600 small">
                <tr>
                  <th>#</th>
                  <th>CODE</th>
                  <th>DESIGNATION</th>
                  <th className="text-end">QUANTITE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>126565</td>
                  <td className="text-truncate">LOGICIEL FACTUREXL</td>
                  <td className="text-end">1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>763809</td>
                  <td className="text-truncate">LICENCE PAR POSTE SUPPLEMENTAIRE (IMMOXL)</td>
                  <td className="text-end">3</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>728394</td>
                  <td className="text-truncate">LOGICIEL POINT DE VENTE</td>
                  <td className="text-end">1</td>
                </tr>
              </tbody>
            </Table>

            <InputGroup>
              <i className="material-icons input-icon text-gray-400">search</i>
              <FormControl
                className="rounded-start input-icon-space"
                placeholder="Articles"
                type="search"
              />
            </InputGroup>
          </div>

          <InputGroup className="mb-3">
            <i className="material-icons input-icon text-gray-400">credit_card</i>
            <FormControl
              className="rounded-start input-icon-space w-auto"
              placeholder="Montant du règlement"
              type="search"
            />
            <Form.Select className="">
              <option disabled>- Type -</option>
              <option>Carte bleue</option>
              <option>Chèque</option>
              <option>Espèces</option>
            </Form.Select>
          </InputGroup>

          <Button className="float-end ms-2">Valider la facture</Button>
          <Button variant="link" className="float-end">Modifier en version complète</Button>




        </Col> 

      </Row>

    </Container>
    */}