import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { InputGroup, FormControl, Container, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, ToastContainer } from 'react-bootstrap';

import { EditionService } from '../../services/EditionService';
import { TarifService } from '../../services/TarifService';

import DynamicTable from '../../components/table/dynamic-table/DynamicTable';
import Pagination from '../../components/pagination/Pagination';
import SideDetails from '../../components/side-details/SideDetails';
import TarifDetails from '../../components/side-details/tarif/tarif-details/TarifDetails';
import TarifEdition from '../../components/side-details/tarif/tarif-edition/TarifEdition';
import ColManager from '../../components/table/dynamic-table/col-manager/ColManager';
import Families from '../../components/families/Families';
import './Tarifs.scss';
import { PreferenceService } from '../../services/PreferenceService';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import ErrorManager from "../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../services/GlobalProvider";
import { formatTableHeadersFromPrefs, granted, getFile } from '../../helper/Helper'

const Tarifs = () => {

  const { globalContextLoaded, preferences, parametres, folderDetails, grants, getPreferences } = React.useContext(GlobalContext);

  let tarifsParams = useRef({
    'start': 0,
    'limit': null,
    'search': null,
    'filters': null,
    'sort': null,
    'order': null,
    'fields': null
  });

  const [globalError, setGlobalError] = useState()
  const [searchParams, setSearchParams] = useSearchParams();
  const [tarifs, setTarifs] = useState()
  const [isGetTarifsLoading, setIsGetTarifsLoading] = useState()
  const [isPDFLoading, setIsPDFLoading] = useState()
  const [sideWidth, setSideWidth] = useState()
  const [tableHeaders, setTableHeaders] = useState()
  const [checkedElementIdList, setCheckedElementIdList] = useState([])
  const [currentPagination, setCurrentPagination] = useState(1)
  const [isTableFilterVisible, setIsTableFilterVisible] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({ show: false, dialogInfos: null })
  const [currentTarif, setCurrentTarif] = useState()
  const [isTarifEditing, setIsTarifEditing] = useState(false)
  const [tarifEditingId, setTarifEditingId] = useState()

  const [tableRowElementActions] = useState([])
  const [tarifElementActions] = useState([])

  let searchRef = useRef(null);

  useEffect(() => {
    if (grants) {

      !grants.tarifs.valeur && setGlobalError({ statusText: `Tarifs : vous n'avez pas les droit d'accès à cette ressource.` })

      granted(grants, "tarifs.edit") && tableRowElementActions.push({ icon: "edit", title: "Modifier le tarif", action: "editTarif" })
      granted(grants, "tarifs.edit") && tarifElementActions.push({ icon: "edit", title: "Modifier le tarif", action: "editTarif" })

      granted(grants, "tarifs.remove") && tableRowElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeTarifDialog" })
      granted(grants, "tarifs.remove") && tarifElementActions.push({ icon: "delete_forever", title: "Supprimer", action: "removeTarifDialog" })

    }
  }, [grants]);

  useEffect(() => {
    if (globalContextLoaded) {
      searchParams.get('tarifId') && getTarif(searchParams.get('tarifId'))
      setPreferences()
      getTarifs()
    }
  }, [globalContextLoaded]);

  function setPreferences() {
    setTableHeaders(formatTableHeadersFromPrefs(preferences.tarifs['tableHeaders'], parametres.tarifs['fieldsLabel']))
    tarifsParams.current['sort'] = preferences.tarifs['tableSort']
    tarifsParams.current['order'] = preferences.tarifs['tableOrder']
    tarifsParams.current['limit'] = preferences.tarifs['tableCount']
    tarifsParams.current['fields'] = Object.keys(preferences?.tarifs.tableHeaders).join(',')
  }

  function getTarif(tarifId) {
    TarifService.getTarif(folderDetails._id, tarifId).then(res => {
      if (!res.data?.data) {
        setGlobalError({ statusText: res.statusText })
      } else {
        changeCurrentTarif(res.data.data)
        setIsTarifEditing(false)
      }
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function getTarifs() {
    setCheckedElementIdList([])
    if (granted(grants, "tarifs.consult")) {
      setIsGetTarifsLoading(true)
      TarifService.getTarifs(folderDetails._id, tarifsParams.current).then(res => {
        setTarifs(res.data.data)
        setIsGetTarifsLoading(false)
      }).catch(error => {
        setIsGetTarifsLoading(false)
        setGlobalError(error)
      });
    }
  }

  function getTarifsPDF(idList) {
    if (granted(grants, "tarifs.consult")) {
      !idList && setIsPDFLoading(true)
      let params = {
        'start': 0,
        'search': tarifsParams.current.search,
        'sort': tarifsParams.current.sort,
        'order': tarifsParams.current.order,
        'filters': tarifsParams.current.filters
      }

      if(idList){
        params['tarifs'] = idList
      }

      EditionService.getListe(folderDetails._id, params, "tarifs", "pdf").then(res => {
        setIsPDFLoading(false)
        if (!res?.data) {
          setGlobalError({ statusText: res.statusText })
        } else {
          getFile(res.data, `Tarifs - Liste`, "pdf")
        }
      }).catch(error => {
        setIsPDFLoading(false)
        setGlobalError(error)
      });
    }
  }

  function removeTarifList(tarifIdList) {
    TarifService.removeTarifs(folderDetails._id, tarifIdList).then(res => {
      setConfirmDialog({ show: false });
      getTarifs()
      changeCurrentTarif()
      setSideWidth()
      uncheckElementList(tarifIdList)
    }).catch(err => {
      setConfirmDialog({
        ...confirmDialog,
        dialogInfos: { ...confirmDialog.dialogInfos, error: err }
      })
    });
  }

  function uncheckElementList(idList) {
    let checkedElementIdListCopy = [...checkedElementIdList];
    idList.map(id => {
      let index = checkedElementIdListCopy.indexOf(id)
      if (index >= 0) {
        checkedElementIdListCopy.splice(index, 1)
      }
    })
    setCheckedElementIdList(checkedElementIdListCopy)
  }

  function saveTableHeadersPrefs(e) {
    const newHeadersObject = {}
    e.forEach(object => {
      newHeadersObject[object['dataTarget']] = { position: object['position'], hidden: object['hidden'] }
    });
    updatePreference({ ["tableHeaders"]: newHeadersObject })
  }

  function updatePreference(prefs) {
    PreferenceService.updatePreferences(folderDetails._id, { prefs: { ['tarifs']: prefs } }).then(res => {
      getPreferences()
    }).catch(error => {
      setGlobalError(error)
    });
  }

  function changeCurrentTarif(tarif) {
    tarif?._id ? setSearchParams({ tarifId: tarif._id }) : setSearchParams(searchParams.delete('tarifId'))
    setCurrentTarif(tarif)
  }

  function changeTableHeaders(e) {
    setTableHeaders(e)
    saveTableHeadersPrefs(e)
  }

  function changePagination(p) {
    setCurrentPagination(p)
    tarifsParams.current['start'] = (tarifsParams.current.limit * p) - tarifsParams.current.limit
    getTarifs()
  }

  function changeLimit(l) {
    tarifsParams.current['limit'] = l
    updatePreference({ ["tableCount"]: l })
    changePagination(1)
  }

  let searchTimeOut;
  function changeSearchValue(value) {
    window.clearTimeout(searchTimeOut);
    searchTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      tarifsParams.current['start'] = 0
      tarifsParams.current['search'] = value ? value : ""
      searchRef.current.value = value ? value : ""
      getTarifs()
    }, 1000);
  }

  let filtersTimeOut;
  function changeFilters(f) {
    window.clearTimeout(filtersTimeOut);
    filtersTimeOut = window.setTimeout(function () {
      setCurrentPagination(1)
      tarifsParams.current['start'] = 0
      tarifsParams.current['filters'] = f
      getTarifs()
    }, 1000);
  }

  function clearFilters() {
    setCurrentPagination(1)
    tarifsParams.current['start'] = 0
    tarifsParams.current['filters'] = null
    getTarifs()
    setIsTableFilterVisible(false)
  }

  function changeSortOrder(s, o) {
    tarifsParams.current['sort'] = s
    tarifsParams.current['order'] = o
    updatePreference({ ["tableSort"]: s, ["tableOrder"]: o })
    getTarifs()
  }

  function onTarifSaved(tarifId) {
    getTarif(tarifId)
    getTarifs()
  }

  function closeSide() {
    setIsTarifEditing(false)
    setCurrentTarif()
  }

  function cancelEdition() {
    setIsTarifEditing(false)
    !currentTarif && setSideWidth()
  }

  function createNewTarif() {
    setIsTarifEditing(true)
    setTarifEditingId()
  }

  function removeTarifListDialog(tarifIdList) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: `Supprimer ${tarifIdList.length} tarif(s) ?`,
        description: 'Voulez vous supprimer les éléments sélectionnés ?',
        actionName: 'Supprimer la sélection',
        btnVariant: 'danger',
        element: tarifIdList,
        error: false,
        confirmAction: 'removeTarifList'
      }
    })
  }

  function removeTarifDialog(tarif) {
    setConfirmDialog({
      show: true,
      dialogInfos: {
        title: tarif.nom,
        description: 'Voulez vous supprimer cet élément ?',
        actionName: 'Supprimer',
        btnVariant: 'danger',
        element: [tarif._id],
        error: false,
        confirmAction: 'removeTarifList'
      }
    })
  }

  function editTarif(tarif) {
    setIsTarifEditing(true)
    setTarifEditingId(tarif._id)
  }

  return (
    <Container fluid id="Tarifs" className="py-4">

      <div className="pb-2 d-flex align-items-center justify-content-between">
        <h4 className="">Tarifs</h4>
        {granted(grants, "tarifs.edit") &&
          <Button variant="primary" className="ms-2" onClick={() => createNewTarif()}>Créer un tarif</Button>
        }
      </div>

      <ErrorManager error={globalError} fixed="true" />

      <ConfirmDialog
        show={confirmDialog.show}
        dialogInfos={confirmDialog.dialogInfos}
        setCancel={() => setConfirmDialog({ show: false })}
        setConfirm={(a, e) => eval(a)(e)}
      />

      <Row>

        <Col xs="12">
          <Row>
            {granted(grants, "tarifs.consult") &&
              <>
                <Col xs="5" xl="3">
                  <InputGroup>
                    <i className="material-icons input-icon text-gray-400">search</i>
                    <FormControl
                      className="input-icon-space rounded-start"
                      placeholder="Recherche globale"
                      type="text"
                      onChange={e => changeSearchValue(e.target.value)}
                      ref={searchRef}
                    />
                    {tarifsParams.current['search'] &&
                      <Button onClick={() => changeSearchValue()} variant="gray-100" className="border border-start-0">
                        <i className="material-icons i-lg">clear</i>
                      </Button>
                    }
                  </InputGroup>
                </Col>
                <Col className="d-flex">
                  {!tarifsParams.current.filters &&
                    <OverlayTrigger placement="right" delay={{ show: 800 }} overlay={<Tooltip>Filtres de recherche</Tooltip>}>
                      <Button className="me-2" variant="secondary" onClick={() => setIsTableFilterVisible(!isTableFilterVisible)}>
                        <i className="material-icons i-lg">filter_alt</i>
                      </Button>
                    </OverlayTrigger>
                  }
                  {tarifsParams.current.filters &&
                    <Button className="me-2" variant="warning" onClick={() => clearFilters()}>
                      Suppr. les filtres
                      <i className="material-icons i-lg ms-2 d-inline-flex">clear</i>
                    </Button>
                  }
                  <ColManager tableHeaders={tableHeaders} changeTableHeaders={e => changeTableHeaders(e)} />
                  <Button disabled={isPDFLoading} variant="secondary" className="me-2" onClick={() => getTarifsPDF()}>
                    {!isPDFLoading && <i className="material-icons i-lg float-start me-2">print</i>}
                    {isPDFLoading && <Spinner animation="border" size="sm" className="me-2" />}
                    Imprimer la liste ({tarifs?.total})
                  </Button>

                  {checkedElementIdList?.length > 0 &&
                    <Dropdown className="d-inline-block">
                      <Dropdown.Toggle variant="dark" id="dropdown-selectionnes">
                        {checkedElementIdList.length} Sélectionné{checkedElementIdList?.length > 1 && <span>s</span>}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => setCheckedElementIdList([])}>Tout désélectionner</Dropdown.Item> */}
                        {granted(grants, "tarifs.remove") &&
                          <>
                            <Dropdown.Item onClick={() => getTarifsPDF(checkedElementIdList)}>Imprimer la liste sélectionnée</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="text-danger" onClick={() => removeTarifListDialog(checkedElementIdList)}>Supprimer</Dropdown.Item>
                          </>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </>
            }
          </Row>

          {granted(grants, "tarifs.consult") &&
            <Row className="mt-3">
              <Col xs="12">
                <div className="p-3 bg-white rounded border overflow-auto">
                  <DynamicTable
                    isDataLoading={isGetTarifsLoading}
                    elementActions={tableRowElementActions}
                    setElementAction={(a, e) => eval(a)(e)}
                    sideWidth={sideWidth}
                    tableHeaders={tableHeaders}
                    tableDataList={tarifs?.tarifs}
                    currentElement={currentTarif}
                    changeCurrentElement={e => changeCurrentTarif(e)}
                    checkedElementIdList={checkedElementIdList}
                    setCheckedElementIdList={e => setCheckedElementIdList(e)}
                    changeFilters={f => changeFilters(f)}
                    isTableFilterVisible={isTableFilterVisible}
                    changeSortOrder={(s, o) => changeSortOrder(s, o)}
                    params={tarifsParams.current}
                  />
                  <Pagination className="mt-3" itemsLength={tarifs?.total} elementsPerPage={tarifsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} showChangeLimit changeLimit={l => changeLimit(l)} />
                </div>
              </Col>
            </Row>
          }

          {(currentTarif || isTarifEditing) &&
            <SideDetails
              editMode={isTarifEditing}
              currentElement={isTarifEditing ? null : { id: currentTarif?._id, label: currentTarif?.code, title: currentTarif?.libelle }}
              changeCurrentElement={e => changeCurrentTarif(e)}
              setSideClose={closeSide}
              setSideCancel={cancelEdition}
              setSideWidth={e => setSideWidth(e)}
              detailsComponent={
                isTarifEditing ?
                  <TarifEdition folderId={folderDetails._id} tarifEditingId={tarifEditingId} onSetSaved={f => onTarifSaved(f)} />
                  :
                  <TarifDetails folderId={folderDetails._id} tarifId={currentTarif._id} elementActions={tarifElementActions} setElementAction={(a, e) => eval(a)(e)} />
              }
            />
          }

        </Col>
      </Row>

    </Container>

  );
}

export default Tarifs;