import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Table, Badge, FormControl, Container, InputGroup, Button, Dropdown, DropdownButton, Form, Col, Row, Spinner, Tooltip, Toast, ToastContainer } from 'react-bootstrap';
import { TarifService } from '../../../../../../services/TarifService';
import { ArticleService } from '../../../../../../services/ArticleService';
import { PreferenceService } from '../../../../../../services/PreferenceService';
import Pagination from '../../../../../pagination/Pagination';
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../../../../helper/Helper'

import './ArticleInput.scss';

const ArticleInput = (props) => {

    const [articles, setArticles] = useState()
    const [isGetArticlesLoading, setIsGetArticlesLoading] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [activeRowIndex, setActiveRowIndex] = useState(0)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [filterChecked, setFilterChecked] = useState()

    const searchRef = useRef(null);

    const outerClick = useOuterClick(ev => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false)
            searchRef.current.blur()
        }
    });

    let articlesParams = useRef({
        'start': 0,
        'limit': 5,
        'search': null,
        'family': null,
        'filters': {},
        'sort': null,
        'order': null
    });

    useEffect(() => {
        getArticles()
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {

        if ((activeRowIndex != null) && (isDropdownOpen)) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < articles?.articles.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                    case "Enter":
                    e.preventDefault();
                    console.log('----------articles.articles[activeRowIndex]')
                    console.log(articles.articles[activeRowIndex])
                    addArticle(articles.articles[activeRowIndex])
                    break;
                case "ArrowRight":
                    break;
                case "ArrowLeft":
                    break;
                case "Space":
                    break;
                /* case "Escape":
                    resetSearch()
                    searchRef.current.blur()
                    break; */
                default:
                    break;
            }
        }
    }

    function getArticles() {
        setIsGetArticlesLoading(true)
        setActiveRowIndex()
        ArticleService.getArticles(props.folderId, articlesParams.current).then(res => {
            setArticles(res.data.data)
            setActiveRowIndex(0)
            setIsGetArticlesLoading(false)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetArticlesLoading(false)
            //setGlobalError(error)
        });
    }

    function addArticle(article) {
        props.setArticle(article)
        setIsDropdownOpen(false)
        searchRef.current && (searchRef.current.value = null)
        articlesParams.current['search'] = null
        articlesParams.current['filters'] = {}
        setFilterChecked()
        setActiveRowIndex(0)
        getArticles()
    }

    let searchTimeOut;
    function changeSearchValue(e) {

        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {

            if (filterChecked) {
                articlesParams.current['search'] = null
            } else {
                articlesParams.current['search'] = e.target.value
            }

            let firstLetter = Array.from(e.target.value)[0]

            function setFilter(name) {
                articlesParams.current['search'] = null
                searchRef.current.value = e.target.value.slice(1)
                setFilterChecked(name)
                articlesParams.current['filters'] = { [`filter[${name}]`]: [{ operator: "", value: searchRef.current.value }] }
            }

            switch (firstLetter) {
                case "#":
                    setFilter("code")
                    break;
                case "/":
                    setFilter("designation")
                    break;
                /* case "*":
                    setFilter("fournisseurs.reference_fournisseur")
                    break; */
                case "!":
                    setFilter("prix_vente_ttc")
                    break;
                default:
                    if (filterChecked) {
                        articlesParams.current['filters'] = { [`filter[${filterChecked}]`]: [{ operator: "", value: searchRef.current.value }] }
                    }
                    break;
            }

            setCurrentPagination(1)
            articlesParams.current['start'] = (articlesParams.current.limit * 1) - articlesParams.current.limit
            getArticles()

        }, 1000);
    }

    function handleFilterChange(name) {

        if (name) {
            articlesParams.current['search'] = null
            setFilterChecked(name)
            articlesParams.current['filters'] = { [`filter[${name}]`]: [{ operator: "", value: searchRef.current.value }] }
        } else {
            setFilterChecked()
            articlesParams.current['filters'] = {}
        }

        setCurrentPagination(1)
        articlesParams.current['start'] = (articlesParams.current.limit * 1) - articlesParams.current.limit
        getArticles()
    }

    function removeSearchValue() {
        setIsDropdownOpen(false)
        searchRef.current.value = null
        articlesParams.current['search'] = null
        articlesParams.current['filters'] = {}
        setFilterChecked()
        setActiveRowIndex(0)
        getArticles()
    }

    function changePagination(p) {
        setCurrentPagination(p)
        articlesParams.current['start'] = (articlesParams.current.limit * p) - articlesParams.current.limit
        getArticles()
    }


    return (
        <div id="TarifArticleInput">
            <InputGroup>
                <i className="material-icons input-icon text-gray-400">search</i>
                {filterChecked &&
                    <span className="search-prefix d-flex justify-content-center align-items-center bg-gray-200 rounded">
                        {filterChecked == "code" && <>#</>}
                        {filterChecked == "designation" && <>/</>}
                        {/* {filterChecked == "fournisseurs.reference_fournisseur" && <>*</>} */}
                        {filterChecked == "prix_vente_ttc" && <>!</>}
                    </span>
                }
                <FormControl
                    className="input-icon-space rounded-start"
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    onFocus={() => setIsDropdownOpen(true)}
                    placeholder="Rechercher un article"
                    disabled={props.disabled}
                />
                {(searchRef?.current?.value || filterChecked) &&
                    <Button disabled={props.disabled} onClick={() => removeSearchValue()} variant="secondary">
                        <i className="material-icons i-lg">clear</i>
                    </Button>
                }
            </InputGroup>


            {isDropdownOpen && (
                <span ref={outerClick} className="zindex-100 border border-gray-500 rounded p-3 position-absolute bg-white shadow-lg drop-container-bottom ">

                    <div className="d-flex flex-column">
                        <h6 className="">Rechercher dans :</h6>
                        <Form.Group className="">

                            <Form.Check
                                inline
                                onChange={() => handleFilterChange("code")}
                                checked={filterChecked == "code"}
                                label="Code"
                                type="radio"
                                id={`inline-1`}
                            />
                            <Form.Check
                                inline
                                onChange={() => handleFilterChange("designation")}
                                checked={filterChecked == "designation"}
                                label="Désignation"
                                type="radio"
                                id={`inline-2`}
                            />
                            {/*                             <Form.Check
                                inline
                                onChange={() => handleFilterChange("fournisseurs.reference_fournisseur")}
                                checked={filterChecked == "fournisseurs.reference_fournisseur"}
                                label="Réf. Fournisseur"
                                type="radio"
                                id={`inline-3`}
                            /> */}
                            <Form.Check
                                inline
                                onChange={() => handleFilterChange("prix_vente_ttc")}
                                checked={filterChecked == "prix_vente_ttc"}
                                label="Prix"
                                type="radio"
                                id={`inline-3`}
                            />

                        </Form.Group>
                    </div>

                    <hr />

                    {isGetArticlesLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetArticlesLoading && !articles?.articles) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetArticlesLoading && articles?.articles) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code</th>
                                        <th>Désignation</th>
                                        <th>Prix TTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articles?.articles?.map((article, i) => (
                                        <tr key={article?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addArticle(article)}>
                                            <td>{article?.code}</td>
                                            <td>{article?.designation}</td>
                                            <td>{article?.prix_vente_ttc}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination className="mt-3" size="sm" itemsLength={articles?.total} elementsPerPage={articlesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>

                    }

                </span>
            )}
        </div>
    );
}

export default ArticleInput;