import React, { useEffect, useState, useRef } from 'react';
import { ClientService } from '../../../../../services/ClientService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tooltip, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { currencyFormat } from '../../../../../helper/Helper'
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';


const Proformas = (props) => {

    const [getProformasLoading, setGetProformasLoading] = useState(false)
    const [proformasData, setProformasData] = useState()
    const [proformasError, setProformasError] = useState()
    const [isFilterVisible, setIsFilterVisible] = useState(false)
    const [currentPagination, setCurrentPagination] = useState(1)

    const [dateDebut, setDateDebut] = useState()
    const [dateFin, setDateFin] = useState()

    let proformasParams = useRef({
        'start': 0,
        'limit': 20,
        'family': null,
        'search': null,
        'filters': null,
        'sort': null,
        'order': null,
        'fields': "type,code,date,client_nom,observation,total_remise,total_taxes,statut,total_prix_revient,total_brut_ht,total_net_ht,total_ttc"
    });

    useEffect(() => {
        getProformas()
    }, []);

    useEffect(() => {
        let dateFilters = []
        dateDebut && dateFilters.push({ operator: ">=", value: dateDebut })
        dateFin && dateFilters.push({ operator: "<=", value: dateFin })
        if (dateFilters.length > 0) {
            proformasParams.current['filters'] = { 'filter[date]': dateFilters }
        } else {
            proformasParams.current['filters'] = null
        }
    }, [dateDebut, dateFin]);

    function changePagination(p) {
        setCurrentPagination(p)
        proformasParams.current['start'] = (proformasParams.current.limit * p) - proformasParams.current.limit
        getProformas()
    }

    function getProformas() {
        setGetProformasLoading(true)
        setProformasError()
        ClientService.getProformas(props.folderId, props.client._id, proformasParams.current).then(res => {
            setGetProformasLoading(false)
            setProformasData(res.data ? res.data.data : null)
        }).catch(error => {
            setGetProformasLoading(false)
            setProformasError(error)
        });
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            proformasParams.current['search'] = value
            setCurrentPagination(1)
            proformasParams.current['start'] = 0
            getProformas()
        }, 1000);
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function clearDateFilters() {
        setDateDebut()
        setDateFin()
        proformasParams.current['filters'] = null
        setIsFilterVisible(false)
        setCurrentPagination(1)
        proformasParams.current['start'] = 0
        getProformas()
    }

    function validFilters() {
        setCurrentPagination(1)
        proformasParams.current['start'] = 0
        getProformas()
    }

    return (
        <>

            {proformasError && <ErrorManager error={proformasError} />}

            {!proformasError &&

                <span className='mb-3 d-block'>


                    <div className="d-flex">
                        <div className="flex-fill pe-3">
                            <InputGroup>
                                <i className="material-icons input-icon text-gray-400">search</i>
                                <FormControl
                                    className="rounded-start input-icon-space"
                                    placeholder="Rechercher"
                                    type="search"
                                    onChange={e => changeSearchValue(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <div className="">
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 800 }}
                                overlay={
                                    <Tooltip>
                                        Date de début et date de fin
                                    </Tooltip>
                                }
                            >
                                <>
                                    <Button disabled={dateDebut || dateFin} className="" variant="secondary" onClick={() => setIsFilterVisible(!isFilterVisible)} >
                                        <i className="material-icons">date_range</i>
                                    </Button>
                                </>
                            </OverlayTrigger>
                        </div>
                    </div>

                    {isFilterVisible &&
                        <>
                            <div className="pt-3 d-flex">
                                <div className="w-50 pe-3">
                                    <InputGroup>
                                        <Form.Control size="sm" onChange={e => setDateDebut(e.target.value)} name="date_debut" placeholder="Date de début" type="date" />
                                    </InputGroup>
                                </div>
                                <div className="w-50 pe-3">
                                    <InputGroup >
                                        <Form.Control size="sm" onChange={e => setDateFin(e.target.value)} name="date_fin" placeholder="Date de fin" type="date" />
                                    </InputGroup>
                                </div>
                                <div className="d-flex">
                                    <Button size="sm" className="me-1" variant="secondary" onClick={() => clearDateFilters()}>Annuler</Button>
                                    <Button size="sm" onClick={() => validFilters()}>Appliquer</Button>
                                </div>
                            </div>
                        </>
                    }

                </span>

            }

            {getProformasLoading && <span className="hloader"></span>}

            {(!getProformasLoading && proformasData?.proformas.length == 0 && !proformasError) && <em>- Aucun élément trouvé -</em>}

            {(!getProformasLoading && !proformasError && proformasData?.proformas.length > 0) &&
                <>

                    <Accordion>

                        {proformasData?.proformas.map(proforma => (

                            <Accordion.Item key={proforma._id} eventKey={proforma._id} className="border-0 mb-3">

                                <Accordion.Header>
                                    <span className="d-flex w-100">{proforma.client_nom} le {new Date(proforma.date).toLocaleDateString()} <span className="ms-auto me-4 text-gray-600">{currencyFormat(proforma.total_ttc)} F TTC</span></span>
                                </Accordion.Header>
                                <Accordion.Body className="px-1">
                                    <Row>
                                        <Col>
                                            <Split border title="Type" value={proforma.type} />
                                            <Split border title="Code" value={proforma.code} />
                                            <Split border title="Date" value={new Date(proforma.date).toLocaleString()} />
                                            <Split border title="Client nom" value={proforma.client_nom} />
                                            <Split border title="Observation" />
                                            {proforma.observation &&
                                                <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(proforma.observation)))} readOnly={true} />
                                            }
                                            {!proforma.observation &&
                                                <em>- Aucune observation rédigée -</em>
                                            }
                                            <Split border title="Total prix revient" value={currencyFormat(proforma.total_prix_revient)} />
                                            <Split border title="Total brut hors taxes" value={currencyFormat(proforma.total_brut_ht)} />
                                            <Split border title="Total net hors taxes" value={currencyFormat(proforma.total_net_ht)} />
                                            <Split border title="Total remise" value={currencyFormat(proforma.total_remise)} />
                                            <Split border title="Total montant taxes" value={currencyFormat(proforma.total_taxes?.montant_total)} />
                                            <Split border title="Total TTC" value={currencyFormat(proforma.total_ttc)} />
                                            <Split border title="Statut" value={proforma.statut} />
                                            <a className="d-flex justify-content-between py-2" target="_blank" href={`/${props.folderId}/proformas/?proformaId=${proforma._id}`}>Voir la proforma complète</a>
                                        </Col>
                                    </Row>

                                </Accordion.Body>

                            </Accordion.Item>
                        ))}
                    </Accordion>

                    <Pagination className="mt-3" itemsLength={proformasData?.total} elementsPerPage={proformasParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                </>
            }



        </>
    );

}
export default Proformas;