import React, { useEffect, useState, useRef } from 'react';
import { ArticleService } from '../../../../../services/ArticleService';
import { FournisseurService } from '../../../../../services/FournisseurService';
import { Spinner, Card, Tabs, Tooltip, Row, Col, Accordion, Button, Table, Alert, Form, Dropdown, OverlayTrigger, Popover, InputGroup, FormControl } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ErrorManager from "../../../../error-manager/ErrorManager";
import ConfirmDialog from '../../../../confirm-dialog/ConfirmDialog';
import Pagination from '../../../../../components/pagination/Pagination';
import { currencyFormat } from '../../../../../helper/Helper'
import { Editor, convertFromHTML, EditorState, createWithContent, convertFromRaw, ContentState, convertToRaw } from "draft-js";
import { markdownToDraft } from 'markdown-draft-js';

// b9be01edae // b9be01ee4c


const Factures = (props) => {

    const [getFacturesLoading, setGetFacturesLoading] = useState(false)
    const [facturesData, setFacturesData] = useState()
    const [facturesError, setFacturesError] = useState()
    const [isFilterVisible, setIsFilterVisible] = useState(false)
    const [currentPagination, setCurrentPagination] = useState(1)

    const [dateDebut, setDateDebut] = useState()
    const [dateFin, setDateFin] = useState()

    let facturesParams = useRef({
        'start': 0,
        'limit': 20,
        'family': null,
        'search': null,
        'filters': null,
        'sort': null,
        'order': null,
        'fields': "type,code,date,client_nom,observation,total_remise,total_taxes,statut,total_prix_revient,total_brut_ht,total_net_ht,total_ttc"
    });

    useEffect(() => {
        getFactures()
    }, []);

    useEffect(() => {
        let dateFilters = []
        dateDebut && dateFilters.push({ operator: ">=", value: dateDebut })
        dateFin && dateFilters.push({ operator: "<=", value: dateFin })
        if (dateFilters.length > 0) {
            facturesParams.current['filters'] = { 'filter[date]': dateFilters }
        } else {
            facturesParams.current['filters'] = null
        }
    }, [dateDebut, dateFin]);

    function changePagination(p) {
        setCurrentPagination(p)
        facturesParams.current['start'] = (facturesParams.current.limit * p) - facturesParams.current.limit
        getFactures()
    }

    function getFactures() {
        setGetFacturesLoading(true)
        setFacturesError()
        ArticleService.getFactures(props.folderId, props.article._id, facturesParams.current).then(res => {
            setGetFacturesLoading(false)
            setFacturesData(res.data ? res.data.data : null)
        }).catch(error => {
            setGetFacturesLoading(false)
            setFacturesError(error)
        });
    }

    let searchTimeOut;
    function changeSearchValue(value) {
        window.clearTimeout(searchTimeOut);
        searchTimeOut = window.setTimeout(function () {
            facturesParams.current['search'] = value
            setCurrentPagination(1)
            facturesParams.current['start'] = 0
            getFactures()
        }, 1000);
    }

    function Split({ title, value, border }) {
        return (
            <div className={"d-flex justify-content-between py-2 " + (border ? "border-bottom" : "")}>
                <span className="pe-4 text-gray-500">{title}</span>
                <span className="">{value}</span>
            </div>
        )
    }

    function clearDateFilters() {
        setDateDebut()
        setDateFin()
        facturesParams.current['filters'] = null
        setIsFilterVisible(false)
        setCurrentPagination(1)
        facturesParams.current['start'] = 0
        getFactures()
    }

    function validFilters() {
        setCurrentPagination(1)
        facturesParams.current['start'] = 0
        getFactures()
    }

    return (
        <>

            {facturesError && <ErrorManager error={facturesError} />}

            {!facturesError &&

                <span className='mb-3 d-block'>


                    <div className="d-flex">
                        <div className="flex-fill pe-3">
                            <InputGroup>
                                <i className="material-icons input-icon text-gray-400">search</i>
                                <FormControl
                                    className="input-icon-space rounded-start"
                                    placeholder="Rechercher"
                                    type="search"
                                    onChange={e => changeSearchValue(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <div className="">
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 800 }}
                                overlay={
                                    <Tooltip>
                                        Date de début et date de fin
                                    </Tooltip>
                                }
                            >
                                <Button disabled={dateDebut || dateFin} className="" variant="secondary" onClick={() => setIsFilterVisible(!isFilterVisible)} >
                                    <i className="material-icons i-lg">date_range</i>
                                </Button>

                            </OverlayTrigger>
                        </div>
                    </div>

                    {isFilterVisible &&
                        <>
                            <div className="pt-3 d-flex">
                                <div className="w-50 pe-3">
                                    <InputGroup>
                                        <Form.Control size="sm" onChange={e => setDateDebut(e.target.value)} name="date_debut" placeholder="Date de début" type="date" />
                                    </InputGroup>
                                </div>
                                <div className="w-50 pe-3">
                                    <InputGroup >
                                        <Form.Control size="sm" onChange={e => setDateFin(e.target.value)} name="date_fin" placeholder="Date de fin" type="date" />
                                    </InputGroup>
                                </div>
                                <div className="d-flex">
                                    <Button size="sm" className="me-1" variant="secondary" onClick={() => clearDateFilters()}>Annuler</Button>
                                    <Button size="sm" onClick={() => validFilters()}>Appliquer</Button>
                                </div>
                            </div>
                        </>
                    }

                </span>

            }

            {getFacturesLoading && <span className="hloader"></span>}

            {(!getFacturesLoading && facturesData?.factures.length == 0 && !facturesError) && <em>- Aucun élément trouvé -</em>}

            {(!getFacturesLoading && !facturesError && facturesData?.factures.length > 0) &&
                <>
                    <Row className="mb-4" >
                        <Col>
                            <Split title="Chiffre d'affaire hors taxes" border value={currencyFormat(facturesData.chiffre_affaire_ht)} />
                            <Split title="Quantité vendue" value={facturesData.quantite_vendu} />
                        </Col>
                    </Row>

                    <Accordion>

                        {facturesData?.factures.map(facture => (

                            <Accordion.Item key={facture._id} eventKey={facture._id} className="border-0 mb-3">

                                <Accordion.Header>
                                    {facture.type == "facture" &&
                                        <span className="py-1 px-2 bg-primary rounded text-white fw-bolder me-2">F</span>
                                    }
                                    {facture.type == "avoir" &&
                                        <span className="py-1 px-2 bg-success rounded text-white fw-bolder me-2">A</span>
                                    }
                                    <span className="d-flex w-100">{facture.client_nom} le {new Date(facture.date).toLocaleDateString()} <span className="ms-auto me-4 text-gray-600">{currencyFormat(facture.total_ttc)} F TTC</span></span>
                                </Accordion.Header>
                                <Accordion.Body className="px-1">
                                    <Row>
                                        <Col>
                                            <Split border title="Type" value={facture.type} />
                                            <Split border title="Code" value={facture.code} />
                                            <Split border title="Date" value={new Date(facture.date).toLocaleString()} />
                                            <Split border title="Client nom" value={facture.client_nom} />
                                            <Split border title="Observation" />
                                            <Editor editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(facture.observation)))} readOnly={true} />

                                            <Split border title="Total prix revient" value={currencyFormat(facture.total_prix_revient)} />
                                            <Split border title="Total brut hors taxes" value={currencyFormat(facture.total_brut_ht)} />
                                            <Split border title="Total net hors taxes" value={currencyFormat(facture.total_net_ht)} />
                                            <Split border title="Total remise" value={currencyFormat(facture.total_remise)} />
                                            <Split border title="Total montant taxes" value={currencyFormat(facture.total_taxes?.montant_total)} />
                                            <Split border title="Total TTC" value={currencyFormat(facture.total_ttc)} />
                                            <Split border title="Statut" value={facture.statut} />
                                            <a className="d-flex justify-content-between py-2" target="_blank" href={`/${props.folderId}/factures/?factureId=${facture._id}`}>Voir la facture complète</a>
                                        </Col>
                                    </Row>

                                </Accordion.Body>


                            </Accordion.Item>
                        ))}
                    </Accordion>

                    <Pagination className="mt-3" itemsLength={facturesData?.total} elementsPerPage={facturesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />
                </>
            }


        </>
    );

}
export default Factures;