import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { ArticleService } from '../../../services/ArticleService';
import Pagination from '../../../components/pagination/Pagination';
import './ArticleInput.scss';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";

const ArticleInput = (props) => {

    const [selectedArticle, setSelectedArticle] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetArticlesLoading, setIsGetArticlesLoading] = useState(false)
    const [articlesError, setArticlesError] = useState()
    const [articles, setArticles] = useState()
    const [articleLoading, setArticleLoading] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [filterChecked, setFilterChecked] = useState("designation")
    const [articleInputLabel, setArticleInputLabel] = useState("")

    const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const searchRef = useRef(null);

    let articlesParams = useRef({
        'start': 0,
        'limit': 8,
        'sort': 'code',
        'filters': props.filters || {}
    });

    useEffect(() => {
        if (!isDropdownOpen) {
            searchRef.current.value = articleInputLabel || ""
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (props.autoFocus && !articleLoading) {
            searchRef.current.focus()
        }
    }, [props.autoFocus, articleLoading]);

    useEffect(() => {
        if (props.articleId) {
            getArticle(props.articleId)
        } else {
            setSelectedArticle()
            setArticleInputLabel()
            searchRef.current.value = null
        }
    }, [props.articleId]);

    const outerClick = useOuterClick(ev => {
        setIsDropdownOpen(false)
    });


    function getArticle(articleId) {
        setArticleLoading(true)
        ArticleService.getArticle(props.folderId, articleId).then(res => {
            setSelectedArticle(res.data.data)
            setArticleInputLabel(`${res.data.data.code} - ${res.data.data.designation}`)
            searchRef.current.value = `${res.data.data.code} - ${res.data.data.designation}`
            setArticleLoading(false)
        }).catch(error => {
            setSelectedArticle()
            setArticleLoading(false)
            setGlobalError(error)
        });
    }

    function addArticle(article) {
        setIsDropdownOpen(false)
        props.setArticle(article)
    }

    function removeArticle() {
        setIsDropdownOpen(false)
        //searchRef.current.value = null
        props.setArticle()
    }

    function openEmptyDropdown() {
        setCurrentPagination(1)
        articlesParams.current['start'] = 0
        articlesParams.current['filters'][`filter[designation]`] = [{ operator: "", value: "" }]
        articlesParams.current['filters'][`filter[code]`] = [{ operator: "", value: "" }]
        setIsDropdownOpen(true)
        getArticles()
    }

    function getArticles() {
        setIsGetArticlesLoading(true)
        setArticlesError()
        setActiveRowIndex()
        ArticleService.getArticles(props.folderId, articlesParams.current).then(res => {
            setIsGetArticlesLoading(false)
            setArticles(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetArticlesLoading(false)
            setArticlesError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        articlesParams.current['start'] = (articlesParams.current.limit * p) - articlesParams.current.limit
        getArticles()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsDropdownOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            articlesParams.current['start'] = 0

            articlesParams.current['filters'][`filter[${filterChecked}]`] = [{ operator: "", value: searchRef.current.value }]
            //articlesParams.current['filters'] = { [`filter[${filterChecked}]`]: [{ operator: "", value: searchRef.current.value }] }
            getArticles()
        }, 1000);

    }

    function handleFilterChange(e) {
        //updatePreference("articleSearchFilter", e.target.name)
        setFilterChecked(e.target.name)
        setCurrentPagination(1)
        articlesParams.current['start'] = 0

        if (e.target.name == "code") {
            articlesParams.current['filters'][`filter[code]`] = [{ operator: "", value: searchRef.current.value }]
            delete articlesParams.current['filters'][`filter[designation]`];
        }
        if (e.target.name == "designation") {
            articlesParams.current['filters'][`filter[designation]`] = [{ operator: "", value: searchRef.current.value }]
            delete articlesParams.current['filters'][`filter[code]`];
        }
        //articlesParams.current['filters'] = { [`filter[${e.target.name}]`]: [{ operator: "", value: searchRef.current.value }] }
        getArticles()
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        const { code } = e;

        if (!isDropdownOpen) {
            switch (code) {
                case "ArrowDown":
                    if (document.activeElement === searchRef.current) {
                        e.preventDefault()
                        openEmptyDropdown()
                    }
                    break;
                default:
                    break;
            }
        }
        if (activeRowIndex != null && isDropdownOpen) {
            switch (code) {
                case "ArrowUp":
                    e.preventDefault()
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault()
                    setActiveRowIndex((activeRowIndex < articles?.articles.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    articles?.articles[activeRowIndex] && addArticle(articles?.articles[activeRowIndex])
                    break;
                case "Escape":
                    e.stopPropagation()
                    setIsDropdownOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div ref={outerClick} id="ArticleInput" className="position-relative">

            <InputGroup hasValidation={props.isInvalid}>

                {!articleLoading &&
                    <i className="material-icons input-icon text-gray-400">search</i>
                }

                <FormControl
                    isInvalid={props.isInvalid}
                    disabled={articleLoading || props.disabled}
                    className="input-icon-space rounded-start"
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    placeholder="Rechercher un article"
                />

                {articleLoading &&
                    <span className="input-icon text-gray-400">
                        <Spinner animation="border" size="sm" className="me-2" />
                    </span>
                }

                {isDropdownOpen ?
                    <Button disabled={articleLoading || props.disabled} onClick={() => setIsDropdownOpen(false)} variant="secondary">
                        <i className="material-icons i-lg">keyboard_arrow_up</i>
                    </Button>
                    :
                    <Button disabled={articleLoading || props.disabled} onClick={() => openEmptyDropdown()} variant="secondary">
                        <i className="material-icons i-lg">keyboard_arrow_down</i>
                    </Button>
                }

                {selectedArticle &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Supprimer
                            </Tooltip>
                        }
                    >
                        <Button disabled={articleLoading || props.disabled} onClick={() => removeArticle()} variant="secondary">
                            <i className="material-icons i-lg">delete_forever</i>
                        </Button>
                    </OverlayTrigger>

                }

                {props.isInvalid &&
                    <Form.Control.Feedback type="invalid">
                        {props.isInvalid}
                    </Form.Control.Feedback>
                }

            </InputGroup>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {isDropdownOpen && (
                <span className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg">

                    <i onClick={() => setIsDropdownOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i>

                    <h6>Rechercher dans :</h6>
                    <Form.Group className="d-flex">
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "code"}
                            label="Code"
                            name="code"
                            type="radio"
                            id={`inline-1`}
                        />
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "designation"}
                            label="Désignation"
                            name="designation"
                            type="radio"
                            id={`inline-2`}
                        />
                    </Form.Group>

                    <hr />

                    {isGetArticlesLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetArticlesLoading && !articles?.articles) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetArticlesLoading && articles?.articles) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articles?.articles?.map((article, i) => (
                                        <tr key={article?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addArticle(article)}>
                                            <td>{article?.code}</td>
                                            <td>
                                                {article?.designation}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination className="mt-3" size="sm" itemsLength={articles?.total} elementsPerPage={articlesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }

                </span>
            )}

        </div>

    )
}
export default ArticleInput;
