import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { FactureService } from '../../../services/FactureService';
import Pagination from '../../../components/pagination/Pagination';
import './FactureListInput.scss';
import ErrorManager from "../../../components/error-manager/ErrorManager";
import { GlobalContext } from "../../../services/GlobalProvider";
import { getFormErrorMsg, useOuterClick, currencyFormat, formattedDateInput } from '../../../helper/Helper'

const FactureListInput = (props) => {


    /* 
    PROPS :
    disabled
    filters
    isInvalid
    folderId
    addedFactureList
    */

    const [selectedFacture, setSelectedFacture] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetFacturesLoading, setIsGetFacturesLoading] = useState(false)
    const [facturesError, setFacturesError] = useState()
    const [factures, setFactures] = useState()
    const [factureLoading, setFactureLoading] = useState(false)
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [addedFactureList, setAddedFactureList] = useState([])

    useEffect(() => {
        props.addedFactureList(addedFactureList)
    }, [addedFactureList]);

    useEffect(() => {
        setAddedFactureList([])
        props.addedFactureList([])
    }, [props.reset]);

    const outerClick = useOuterClick(ev => {
        if (isPanelOpen) {
            setIsPanelOpen(false)
        }
    });

    // const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const searchRef = useRef(null);

    let facturesParams = useRef({
        'start': 0,
        'limit': 8,
        'sort': "date",
        'order': "desc",
        'filters': props.filters || {}
    });

    function getFacture(factureId) {
        FactureService.getFacture(props.folderId, factureId).then(res => {
            console.log(res.data.data)
        }).catch(error => {
            setGlobalError(error)
        });
    }

    function addFacture(facture) {
        if (!addedFactureList.find(f => f._id == facture._id)) {
            setAddedFactureList(addedFactureList => [...addedFactureList, facture]);
        }
    }

    function showFactureList() {
        setIsPanelOpen(true)
        searchRef.current.value = null
        getFactures()
    }

    function removeFacture(facture) {
        setAddedFactureList(addedFactureList.filter(f => f._id !== facture._id));
    }

    function getFactures() {
        setIsGetFacturesLoading(true)
        setFacturesError()
        setActiveRowIndex()
        FactureService.getFactures(props.folderId, facturesParams.current).then(res => {
            setIsGetFacturesLoading(false)
            setFactures(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetFacturesLoading(false)
            setFacturesError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        facturesParams.current['start'] = (facturesParams.current.limit * p) - facturesParams.current.limit
        getFactures()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsPanelOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            facturesParams.current['start'] = 0
            facturesParams.current['search'] = searchRef.current.value
            getFactures()
        }, 1000);
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        if (activeRowIndex != null && isPanelOpen) {
            const { code } = e;
            switch (code) {
                case "ArrowUp":
                    e.preventDefault();
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    setActiveRowIndex((activeRowIndex < factures?.factures.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    e.preventDefault();

                    if (addedFactureList.find(f => f._id == factures.factures[activeRowIndex]._id)) {
                        removeFacture(factures.factures[activeRowIndex])
                    } else {
                        addFacture(factures.factures[activeRowIndex])
                    }

                    break;
                case "Escape":
                    setIsPanelOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div id="factureListInput" className="position-relative">

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            <div className="position-relative">

                <InputGroup hasValidation={props.isInvalid}>

                    <FormControl
                        isInvalid={props.isInvalid}
                        disabled={props.disabled}
                        className=""
                        type="text"
                        ref={searchRef}
                        onChange={changeSearchValue}
                        placeholder="Rechercher une facture"
                    />

                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Afficher les factures
                            </Tooltip>
                        }
                    >
                        <Button disabled={props.disabled} onClick={() => showFactureList()} variant="secondary">
                            <i className="material-icons i-lg">expand_more</i>
                        </Button>
                    </OverlayTrigger>

                    {props.isInvalid &&
                        <Form.Control.Feedback type="invalid">
                            {props.isInvalid}
                        </Form.Control.Feedback>
                    }

                </InputGroup>

                {isPanelOpen && (
                    <span ref={outerClick} className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 end-0 bg-white shadow-lg">

                        <div className="w-100 mb-3">
                            <i onClick={() => setIsPanelOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i>
                        </div>

                        {isGetFacturesLoading &&
                            <span className="hloader"></span>
                        }

                        {(!isGetFacturesLoading && !factures?.factures) &&
                            <em>- Aucun élément trouvé -</em>
                        }

                        {(!isGetFacturesLoading && factures?.factures) &&
                            <>
                                <Table size="sm" borderless hover className="rounded">
                                    <thead className="text-uppercase text-gray-600 small">
                                        <tr>
                                            <th>Code</th>
                                            <th>Date</th>
                                            <th className="text-end">Total TTC</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {factures?.factures?.map((facture, i) => (
                                            <tr onClick={() => addFacture(facture)} key={facture?._id} className={"align-middle cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")}>
                                                <td>{facture?.code}</td>
                                                <td>{new Date(facture.date).toLocaleString()}</td>
                                                <td className="text-end">{currencyFormat(facture?.total_ttc)}</td>
                                                <td className="text-end">
                                                    {addedFactureList.find(f => f._id == facture._id) &&
                                                        <Button onClick={() => removeFacture(facture)} className="ms-2 py-0 px-2" size="sm" variant="danger">Suppr.</Button>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Pagination className="mt-3" size="sm" itemsLength={factures?.total} elementsPerPage={facturesParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                            </>
                        }

                    </span>
                )}
            </div>

            {(addedFactureList.length > 0) &&
                <div className="mt-2">
                    {addedFactureList.map(facture => (
                        <span key={facture._id} className="d-inline-flex align-items-center bg-gray-100 rounded me-2 mb-2 p-1 small">
                            <span className="me-2 text-gray-600">
                                {facture.code} ({currencyFormat(facture.total_ttc)}F)
                            </span>
                            <span onClick={() => removeFacture(facture)} className="px-1 bg-gray-300 hover-bg-gray-400 rounded cursor-pointer">
                                <i className="material-icons ">
                                    close
                                </i>
                            </span>
                        </span>
                    ))}
                </div>
            }

        </div>

    )
}
export default FactureListInput;
