import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Dropdown, DropdownButton, Form, InputGroup, Row, Badge, Label, Table, Tooltip, Spinner, OverlayTrigger, FormControl } from 'react-bootstrap';
import { ContributionService } from '../../../services/ContributionService';
import { ClientService } from '../../../services/ClientService';
import Pagination from '../../../components/pagination/Pagination';
import './ClientInput.scss';
import { GlobalContext } from "../../../services/GlobalProvider";
import { useOuterClick, currencyFormat, granted, formattedDateInput, getFormErrorMsg } from '../../../helper/Helper'
import ErrorManager from "../../../components/error-manager/ErrorManager";


const ClientInput = (props) => {

    const [selectedClient, setSelectedClient] = useState()
    const [currentPagination, setCurrentPagination] = useState(1)
    const [isGetClientsLoading, setIsGetClientsLoading] = useState(false)
    const [clientsError, setClientsError] = useState()
    const [clients, setClients] = useState()
    const [clientLoading, setClientLoading] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [activeRowIndex, setActiveRowIndex] = useState(1)
    const [globalError, setGlobalError] = useState()
    const [filterChecked, setFilterChecked] = useState("nom")
    const [clientInputLabel, setClientInputLabel] = useState("")

    const { parametres, folderDetails, userDetails, grants } = React.useContext(GlobalContext);

    const searchRef = useRef(null);

    let clientsParams = useRef({
        'start': 0,
        'limit': 8,
        'sort': 'code',
        'filters': props.filters || {}
    });

    useEffect(() => {
        if (!isDropdownOpen) {
            searchRef.current.value = clientInputLabel || ""
        }
    }, [isDropdownOpen]);

    useEffect(() => {
        if (props.autoFocus && !clientLoading) {
            searchRef.current.focus()
        }
    }, [props.autoFocus, clientLoading]);

    useEffect(() => {
        if (props.clientId) {
            getClient(props.clientId)
        } else {
            setSelectedClient()
            setClientInputLabel()
            searchRef.current.value = null
        }
    }, [props.clientId]);

    const outerClick = useOuterClick(ev => {
        setIsDropdownOpen(false)
    });

    function getClient(clientId) {
        setClientLoading(true)
        ClientService.getClient(props.folderId, clientId).then(res => {
            setSelectedClient(res.data.data)
            setClientInputLabel(`${res.data.data.code} - ${res.data.data.nom}`)
            searchRef.current.value = `${res.data.data.code} - ${res.data.data.nom}`
            setClientLoading(false)
        }).catch(error => {
            setSelectedClient()
            setClientLoading(false)
            setGlobalError(error)
        });
    }

    function addClient(client) {
        setIsDropdownOpen(false)
        props.setClient(client)
    }

    function removeClient() {
        setIsDropdownOpen(false)
        //searchRef.current.value = null
        props.setClient()
    }

    function openEmptyDropdown() {
        setCurrentPagination(1)
        clientsParams.current['start'] = 0
        clientsParams.current['filters'][`filter[nom]`] = [{ operator: "", value: "" }]
        clientsParams.current['filters'][`filter[code]`] = [{ operator: "", value: "" }]
        setIsDropdownOpen(true)
        getClients()
    }

    function getClients() {
        setIsGetClientsLoading(true)
        setClientsError()
        setActiveRowIndex()
        ClientService.getClients(props.folderId, clientsParams.current).then(res => {
            setIsGetClientsLoading(false)
            setClients(res.data ? res.data.data : null)
            setActiveRowIndex(0)
        }).catch(error => {
            setActiveRowIndex()
            setIsGetClientsLoading(false)
            setClientsError(error)
        });
    }

    function changePagination(p) {
        setCurrentPagination(p)
        clientsParams.current['start'] = (clientsParams.current.limit * p) - clientsParams.current.limit
        getClients()
    }

    let searchTimeOut;
    function changeSearchValue(e) {
        window.clearTimeout(searchTimeOut);
        setIsDropdownOpen(true)
        searchTimeOut = window.setTimeout(function () {
            setCurrentPagination(1)
            searchRef.current.value = e.target.value
            clientsParams.current['start'] = 0

            clientsParams.current['filters'][`filter[${filterChecked}]`] = [{ operator: "", value: searchRef.current.value }]
            //clientsParams.current['filters'] = { [`filter[${filterChecked}]`]: [{ operator: "", value: searchRef.current.value }] }
            getClients()
        }, 1000);

    }

    function handleFilterChange(e) {
        //updatePreference("clientSearchFilter", e.target.name)
        setFilterChecked(e.target.name)
        setCurrentPagination(1)
        clientsParams.current['start'] = 0

        if (e.target.name == "code") {
            clientsParams.current['filters'][`filter[code]`] = [{ operator: "", value: searchRef.current.value }]
            delete clientsParams.current['filters'][`filter[nom]`];
        }
        if (e.target.name == "nom") {
            clientsParams.current['filters'][`filter[nom]`] = [{ operator: "", value: searchRef.current.value }]
            delete clientsParams.current['filters'][`filter[code]`];
        }
        //clientsParams.current['filters'] = { [`filter[${e.target.name}]`]: [{ operator: "", value: searchRef.current.value }] }
        getClients()
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    function handleKeyDown(e) {
        const { code } = e;

        if (!isDropdownOpen) {
            switch (code) {
                case "ArrowDown":
                    if (document.activeElement === searchRef.current) {
                        e.preventDefault()
                        openEmptyDropdown()
                    }
                    break;
                default:
                    break;
            }
        }
        if (activeRowIndex != null && isDropdownOpen) {
            switch (code) {
                case "ArrowUp":
                    e.preventDefault()
                    setActiveRowIndex(activeRowIndex != 0 && activeRowIndex - 1)
                    break;
                case "ArrowDown":
                    e.preventDefault()
                    setActiveRowIndex((activeRowIndex < clients?.clients.length - 1) ? activeRowIndex + 1 : activeRowIndex)
                    break;
                case "Enter":
                    clients?.clients[activeRowIndex] && addClient(clients?.clients[activeRowIndex])
                    break;
                case "Escape":
                    e.stopPropagation()
                    setIsDropdownOpen(false)
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div ref={outerClick} id="ClientInput" className="position-relative">

            <InputGroup hasValidation={props.isInvalid}>

                {!clientLoading &&
                    <i className="material-icons input-icon text-gray-400">search</i>
                }

                <FormControl
                    isInvalid={props.isInvalid}
                    disabled={clientLoading || props.disabled}
                    className="input-icon-space rounded-start"
                    type="text"
                    ref={searchRef}
                    onChange={changeSearchValue}
                    placeholder="Rechercher un client"
                />

                {clientLoading &&
                    <span className="input-icon text-gray-400">
                        <Spinner animation="border" size="sm" className="me-2" />
                    </span>
                }

                {isDropdownOpen ?
                    <Button disabled={clientLoading || props.disabled} onClick={() => setIsDropdownOpen(false)} variant="secondary">
                        <i className="material-icons i-lg">keyboard_arrow_up</i>
                    </Button>
                    :
                    <Button disabled={clientLoading || props.disabled} onClick={() => openEmptyDropdown()} variant="secondary">
                        <i className="material-icons i-lg">keyboard_arrow_down</i>
                    </Button>
                }


                {props.clientComptantButton == "true" &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Client comptant
                            </Tooltip>
                        }
                    >
                        <Button disabled={clientLoading || props.disabled} onClick={() => addClient(props.clientComptant)} variant="secondary">
                            <i className="material-icons i-lg">account_box</i>
                        </Button>
                    </OverlayTrigger>

                }

                {selectedClient &&
                    <OverlayTrigger
                        delay={{ show: 800 }}
                        overlay={
                            <Tooltip>
                                Supprimer
                            </Tooltip>
                        }
                    >
                        <Button disabled={clientLoading || props.disabled} onClick={() => removeClient()} variant="secondary">
                            <i className="material-icons i-lg">delete_forever</i>
                        </Button>
                    </OverlayTrigger>

                }

                {props.isInvalid &&
                    <Form.Control.Feedback type="invalid">
                        {props.isInvalid}
                    </Form.Control.Feedback>
                }

            </InputGroup>

            {globalError && (
                <ErrorManager error={globalError} />
            )}

            {isDropdownOpen && (
                <span className="drop-container zindex-100 border border-gray-500 rounded p-3 position-absolute top-100 bg-white shadow-lg">

                    <i onClick={() => setIsDropdownOpen(false)} className="material-icons text-gray-300 fs-4 position-absolute top-0 end-0 me-2 mt-1 cursor-pointer">close</i>

                    <h6>Rechercher dans :</h6>
                    <Form.Group className="d-flex">
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "code"}
                            label="Code"
                            name="code"
                            type="radio"
                            id={`inline-1`}
                        />
                        <Form.Check
                            inline
                            onChange={handleFilterChange}
                            checked={filterChecked == "nom"}
                            label="Nom"
                            name="nom"
                            type="radio"
                            id={`inline-2`}
                        />
                    </Form.Group>

                    <hr />

                    {isGetClientsLoading &&
                        <span className="hloader"></span>
                    }

                    {(!isGetClientsLoading && !clients?.clients) &&
                        <em>- Aucun élément trouvé -</em>
                    }

                    {(!isGetClientsLoading && clients?.clients) &&
                        <>
                            <Table size="sm" hover className="rounded">
                                <thead className="text-uppercase text-gray-600 small">
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients?.clients?.map((client, i) => (
                                        <tr key={client?._id} className={"cursor-pointer position-relative " + (i == activeRowIndex ? "table-active selected zindex-1 " : "")} onClick={() => addClient(client)}>
                                            <td>{client?.code}</td>
                                            <td>
                                                {client?.nom}
                                                <Badge className="float-end" bg="secondary">{client?.type}</Badge>
                                                {client?.bloquer &&
                                                    <Badge className="me-2 float-end" bg="danger">Bloqué</Badge>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <Pagination className="mt-3" size="sm" itemsLength={clients?.total} elementsPerPage={clientsParams.current.limit} currentPagination={currentPagination} changePagination={p => changePagination(p)} />

                        </>
                    }

                </span>
            )}

        </div>

    )
}
export default ClientInput;
